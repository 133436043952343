import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enCommon from "./utils/translation/en/common.json";
import deCommon from "./utils/translation/de/common.json";
import arCommon from "./utils/translation/ar/common.json";
import frCommon from "./utils/translation/fr/common.json";
import nlCommon from "./utils/translation/nl/common.json";

import enDashboard from "./utils/translation/en/dashboard.json";
import deDashboard from "./utils/translation/de/dashboard.json";
import arDashboard from "./utils/translation/ar/dashboard.json";
import frDashboard from "./utils/translation/fr/dashboard.json";
import nlDashboard from "./utils/translation/nl/dashboard.json";



i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          ...enCommon,
          ...enDashboard,
        }
      },
      de: {
        translation: {
          ...deCommon,
          ...deDashboard,
        }
      },
      ae: {
        translation: {
          ...arCommon,
          ...arDashboard,
        }
      },
      fr: {
        translation: {
          ...frCommon,
          ...frDashboard,
        }
      },
      nl: {
        translation: {
          ...nlCommon,
          ...nlDashboard,
        }
      },
    },
    debug: true,
    fallbackLng: "en",
    lng: "en",
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ["cookie"],
    },
  });
