import React from 'react';
import FacbookIcon from '../assets/images/facebook.svg';
import TwittrIcon from '../assets/images/twitter.svg';
import LinkdinIcon from '../assets/images/linkdin.svg';
import WhatsappIcon from '../assets/images/whatsapp.svg';
import { useTranslation } from 'react-i18next';

const Sociallist = () => {
    const { t } = useTranslation();


    return (
        <>
            <ul className='SocialList flex gap-[10px] justify-center sm:justify-start'>
                <li><a href='#'><img className="hover:scale-110" src={FacbookIcon} /></a></li>
                <li><a href='#'><img className="hover:scale-110" src={TwittrIcon} /></a></li>
                <li><a href='#'><img className="hover:scale-110" src={LinkdinIcon} /></a></li>
                <li><a href='#'><img className="hover:scale-110" src={WhatsappIcon} /></a></li>
            </ul> 
        </>
    )
}
 
export default Sociallist