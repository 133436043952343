export const baseURL = process.env.REACT_APP_BASEURL;
export const timeFormat = 'hh:mm A';
export const dateFormat = 'DD MMM YYYY';
export const dateTimeFormat = 'DD MMM YYYY, hh:mm A';
export const limitId = 10;

export const dutsoRecipes = [
 

  {
    imagepath: require('../assets/images/recipe3.jpg'),
    cardlink: '#',
    title: "India se biryani recipe",
    category1: "Biryani",
    category2: "Ghee",
    readingTime: "3 Min"
  },
  {
    imagepath: require('../assets/images/recipe4.jpg'),
    cardlink: '#',
    title: "India se biryani recipe",
    category1: "Biryani",
    category2: "Ghee",
    readingTime: "6 Min"
  },
   
  {
    imagepath: require('../assets/images/blog1.png'),
    cardlink: '#',
    title: "India se biryani recipe se biryani India",
    category1: "Biryani",
    category2: "Ghee",
    readingTime: "8 Min"
  }
];


export const blogList = [
  
  
  {
    blogImage: require('../assets/images/recipe3.jpg'),
    blogDate: "28 March 2024",
    blogTitle: "Good and bad fats: what exactly does that mean?",
    blogExcerpt: "Ghee originally comes from India. Ayuverdic cuisine is widely used in India. 'Ayurveda' literally means 'knowl everything on earth has life energy, or 'prana'. Ayurvedic nutrition uses 5 tastes, namely: sweet, sour, salty, sharp, bitter and astringent.",
    blogUrl: '#'
  },
  {
    blogImage: require('../assets/images/recipe4.jpg'),
    blogDate: "28 March 2024",
    blogTitle: "Good and bad fats: what exactly does that mean?",
    blogExcerpt: "hat everything on earth has life energy, or 'prana'. Ayurvedic nutrition uses 5 tastes, namely: sweet, sour, salty, sharp, bitter and astringent.",
    blogUrl: '#'
  }
];


export const productGallery = [
  {
    productIamge: require('../assets/images/recipe3.jpg'),
  },
  {
    productIamge: require('../assets/images/recipe4.jpg'),
  }
];


export const prductSpecifications = [
  {
    column1: 'Dutso Ghee | 1KG',
    column2: '1 el (14g)',
    column3: '100g',
  },
  {
    column1: 'Dutso Ghee | 1KG',
    column2: '126 Kcal / 517 kJ',
    column3: '898 Kcal / 3693 kJ',
  },
  {
    column1: 'Vet',
    column2: '13,9 g',
    column3: '99,9 g',
  },
  {
    column1: 'Verzadigd vet',
    column2: '9,8 g',
    column3: '70 g',
  },
  {
    column1: 'Enkelvoudig onverzadigd vet',
    column2: '3,8 g',
    column3: '27 g',
  },
  {
    column1: 'Meervoudig onverzadigd vet',
    column2: '0,4 g',
    column3: '3 g',
  },
  {
    column1: 'Eiwit',
    column2: '0,01 g',
    column3: '0,05 g',
  },
  {
    column1: 'Koolhydraten',
    column2: '0,01 g',
    column3: '0,05 g',
  },
  {
    column1: 'Zout',
    column2: '0 g',
    column3: '0 g',
  },
  {
    column1: 'Vitamine A',
    column2: '0,13 mg',
    column3: '0,9 mg',
  },
  {
    column1: 'Vitamine D',
    column2: '0,2 µg',
    column3: '1,6 µg',
  },
  {
    column1: 'Vitamine E',
    column2: '0,13 mg',
    column3: '0,9 mg',
  },
  {
    column1: 'Vitamine K',
    column2: '0,2 µg',
    column3: '0,9 mg',
  },
];



const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Previous
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Next
    </div>
  );
};

export let sliderSettings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
  autoplay: true,
  autoplaySpeed: 2500,
  centerPadding: '0',
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false
      }
    },
    {
      breakpoint: 639,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false
      }
    }
  ]
};

export let globalSetting = {
  address: "Noida Uttar Pradesh",
  cod_status: null,
  company_name: "Dutso pvt ltd",
  contact: "+91747733737",
  default_currency: "€",
  default_date_format: "MMM D, YYYY",
  default_time_zone: "Asia/Riyadh",
  email: "dutso@gmail.com",
  favicon: null,
  fb_pixel_key: null,
  fb_pixel_status: null,
  google_analytic_key: null,
  google_analytic_status: null,
  google_client_id: null,
  google_login_status: null,
  google_secret_key: null,
  meta_description: null,
  meta_img: null,
  meta_keywords: null,
  meta_title: null,
  meta_url: null,
  name: "globalSetting",
  number_of_image_per_product: "5",
  post_code: "2030",
  receipt_size: "57-mm",
  shop_name: "Dutso",
  stripe_key: null,
  stripe_secret: null,
  stripe_status: null,
  tawk_chat_property_id: null,
  tawk_chat_status: null,
  tawk_chat_widget_id: null,
  vat_number: "47589",
  website: "dutso.dairy.com"
};
