import requests from "./httpServices";

const CmsServices = {

  getFaqs: async () => {
    return requests.get(`/faq/all`);
  },

  getAllBanners: async () => {
    return requests.get(`/banner/all`);
  },

  getAllVideos: async () => {
    return requests.get(`/video/all`);
  },

  getAllPartners: async () => {
    return requests.get(`/partner/all`);
  },

  getPageDetailBySlug: async (slug) => {
    return requests.get(`/page/${slug}`);
  },

 
};

export default CmsServices;
