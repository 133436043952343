import React, { useContext, useState } from "react";
import { ImFacebook, ImGoogle } from "react-icons/im";

//internal import
import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";
import { useTranslation } from "react-i18next";
import { SidebarContext } from "../../context/SidebarContext";

const Common = ({ setModalOpen }) => {
  const [showResetPassword, setShowResetPassword] = useState(false);
  const { showRegister, setShowRegister } = useContext(SidebarContext)

  const { t } = useTranslation();


  const handleModal = () => {
    setShowRegister(!showRegister);
    setShowResetPassword(false);
  };

  return (
    <>
      <div className="overflow-hidden bg-white w-full h-full flex item-center  flex-wrap md:flex-nowrap">
        {showResetPassword ? (
          <ResetPassword
            setShowResetPassword={setShowResetPassword}
            setModalOpen={setModalOpen}
          />
        ) : showRegister ? (
          <Register
            setShowResetPassword={setShowResetPassword}
            setModalOpen={setModalOpen}
          />
        ) : (
          <Login
            setShowResetPassword={setShowResetPassword}
            setModalOpen={setModalOpen}
          />
        )}
        <div className="text-center text-sm text-gray-900 md:absolute left-0 mt-[15px] bottom-[0px]  w-full">
          <div className="text-[color:var(--color-primary)] mt-2.5">
            {showRegister ? t('AlreadyHaveAccount') : t("NotHaveAccount")} ?
            <button
              onClick={handleModal}
              className="text-[color:var(--color-primary)] hover:text-emerald-500 font-bold mx-2"
            >
              {showRegister ? t("Login") : t("Register")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Common;
