import requests from "./httpServices";

const CustomerServices = {
  customerLogin: async (body) => {
    return requests.post("/customer/login", body);
  },

  verifyEmailAddress: async (body) => {
    return requests.post("/customer/verify-email", body);
  },

  registerCustomer: async (body) => {
    return requests.post('/customer/register', body);
  },

  signUpWithProvider(token, body) {
    return requests.post(`/customer/signup/${token}`, body);
  },

  forgetPassword: async (body) => {
    return requests.put("/customer/forget-password", body);
  },

  resetPassword: async (body) => {
    return requests.put("/customer/reset-password", body);
  },

  changePassword: async (body) => {
    return requests.post("/customer/change-password", body);
  },

  updateCustomer: async (id, body) => {
    return requests.put(`/customer/${id}`, body);
  },

  getCustomerById: async (id) => {
    return requests.get(`/customer/${id}`);
  },

  sendQueryforContactMessage: async (body) => {
    return requests.post("/setting/send_contact_message", body);
  },

  createReview: async (body) => {
    return requests.post("/review/add", body);
  },

  getCustomerReveiw: async (id) => {
    return requests.get(`/review/customer/${id}`);
  },

  updateReview: async (body , id) => {
    return requests.put(`/review/${id}`, body);
  },

  getCustomersReviews: async () => {
    return requests.get(`/review/show`);
  },

};

export default CustomerServices;
