import React, { lazy, Suspense, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from "../Layouts";
import { UserContext } from '../context/UserContext';
const HomePage = lazy(() => import('../pages/HomePage'));
const About = lazy(() => import('../pages/About'));
const Shop = lazy(() => import('../pages/Shop'));
const Recipes = lazy(() => import('../pages/Recipes'));
const Blogs = lazy(() => import('../pages/Blogs'));
const Health = lazy(() => import('../pages/Health'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const Checkout = lazy(() => import('../pages/Checkout'));
const BusinessCheckout = lazy(() => import('../pages/BusinessCheckout'));
const ProductDetails = lazy(() => import('../pages/ProductDetails'));
const ProductDetailsForB2B = lazy(() => import('../pages/ProductDetailsForB2B'));
const BlogSingle = lazy(() => import('../pages/BlogSingle'));
const RecipeSingle = lazy(() => import('../pages/RecipeSingle'));
const ResetPassword = lazy(() => import('../pages/user/forget-password/ResetPassword'));
const MyOrders = lazy(() => import('../pages/user/MyOrders'));
const Account = lazy(() => import('../pages/user/Account'));
const MyAddress = lazy(() => import('../pages/user/MyAddress'));
const Review = lazy(() => import('../pages/user/AddReview'));
const ChangePassword = lazy(() => import('../pages/user/ChangePassword'));
const OrderSummary = lazy(() => import('../pages/order/OrderSummary'));
const ViewOrderInvoice = lazy(() => import('../pages/order/ViewOrderInvoice'));
const BusinessOrderInvoice = lazy(() => import('../pages/order/BusinessOrderInvoice'));
const OfferInvoice = lazy(() => import('../pages/order/OfferInvoice'));
const Faq = lazy(() => import('../pages/Faq'));
const Terms = lazy(() => import('../pages/terms-conditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const ReturnPolicy = lazy(() => import('../pages/ReturnPolicy'));
const Disclaimer = lazy(() => import('../pages/Disclaimer'));
const PartnerWithUs = lazy(() => import('../pages/PartnerWithUs'));
const PageNoteFound = lazy(() => import('../pages/PageNoteFound'));





function RootRouters() {
    const { state: { userInfo } } = useContext(UserContext);
    return (
        <Suspense fallback={<div className='text-center flex-auto text-lg font-semibold text-slate-900'></div>}>
            <Routes>
                <Route exact path="/user/forget-password/:id" element={<ResetPassword />} />
                <Route path="/" element={<Layout><HomePage /></Layout>} />
                <Route exact path="/about" element={<Layout><About /></Layout>} />
                <Route exact path="/shop" element={<Layout><Shop /></Layout>} />
                <Route exact path="/recipes" element={<Layout><Recipes /></Layout>} />
                <Route exact path="/blog" element={<Layout><Blogs /></Layout>} />
                <Route exact path="/faq" element={<Layout><Faq /></Layout>} />
                <Route exact path="/health" element={<Layout><Health /></Layout>} />
                <Route exact path="/contact-us" element={<Layout><ContactUs /></Layout>} />
                <Route exact path="/checkout" element={<Layout>{userInfo?.role === "Company" ? <BusinessCheckout /> : <Checkout />}</Layout>} />
                <Route exact path="/user/account" element={<Layout><Account /></Layout>} />
                <Route exact path="/user/my-orders" element={<Layout><MyOrders /></Layout>} />
                <Route exact path="/user/change-password" element={<Layout><ChangePassword /></Layout>} />
                <Route exact path="/user/address" element={<Layout><MyAddress /></Layout>} />
                <Route exact path="/user/review" element={<Layout><Review /></Layout>} />
                <Route exact path="/order-success/:id" element={<Layout><OrderSummary /></Layout>} />
                <Route exact path="/view-order-invoice/:id" element={<Layout><ViewOrderInvoice /></Layout>} />
                <Route exact path="/business-order-invoice/:id" element={<Layout><BusinessOrderInvoice /></Layout>} />
                <Route exact path="/business-offer-invoice/:id" element={<Layout><OfferInvoice /></Layout>} />
                <Route exact path="/product/:slug" element={<Layout>{userInfo?.role === "Company" ? <ProductDetailsForB2B /> : <ProductDetails />}</Layout>} />
                <Route exact path="/blog/:slug" element={<Layout><BlogSingle /></Layout>} />
                <Route exact path="/recipe/:slug" element={<Layout><RecipeSingle /></Layout>} />
                <Route exact path="/terms-condition" element={<Layout><Terms /></Layout>} />
                <Route exact path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
                <Route exact path="/return-policy" element={<Layout><ReturnPolicy /></Layout>} />
                <Route exact path="/disclaimer" element={<Layout><Disclaimer /></Layout>} />
                <Route exact path="/partner-with-us" element={<Layout><PartnerWithUs /></Layout>} />
                <Route exact path="/partner-with-us" element={<Layout><PartnerWithUs /></Layout>} />
                <Route exact path="*" element={<PageNoteFound />} />
            </Routes>
        </Suspense>
    );
}

export default RootRouters;
