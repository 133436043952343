import React from 'react';
import FooterLogo from '../assets/images/footer-logo.png';
import Navbarcompany from './Navbarcompany';
import Sociallist from './Sociallist';
import Navbarfooter from './Navbarfooter';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="footerContainer bg-[#1E2124] pt-[100px] pb-[20px] text-[#C1C1C1]">
                <div className="footerWrapper container m-auto">
                    <div className='grid gap-y-[30px] sm:gap-y-[0px] grid-cols-1 sm:grid-cols-3'>
                        <div className='order-2 sm:order-1 border-b pb-[20px] sm:pb-0 sm:border-b-0 border-white/[.15]'>
                            <div className='coulm-wrapper flex flex-col '>
                                <h4 className='mb-[20px] sm:mb-[30px] text-center sm:text-left'>{t("Company")}</h4>
                                <Navbarcompany />
                            </div>
                        </div>
                        <div className='order-3 sm:order-2 border-b pb-[20px] sm:pb-0 sm:border-b-0 border-white/[.15]'>
                            <div className='coulm-wrapper flex flex-col '>
                                <h4 className='mb-[20px] sm:mb-[30px] text-center sm:text-left'>{t("ImportantLinks")}</h4>
                                <Navbarfooter />
                            </div>
                        </div>
                       
                        <div className='order-1 sm:order-4 border-b pb-[20px] sm:pb-0 sm:border-b-0 '>
                            <div className='coulm-wrapper flex flex-col '>
                                <img alt='dutso_footer' className='w-[150px] m-auto sm:m-0 sm:m text-center sm:text-left-0 sm:max-w-[100px] mb-[20px] sm:mb-[30px]' src={FooterLogo} />
                                <div className='PersonalInfo text-center sm:text-left'>
                                    <span>{t("Location")}</span>
                                    <span className='mt-3 block'>
                                        <span className='mt-3 block'>{t('ChamberOfCommerce')}</span>
                                        <span className='block'>78738040</span>
                                        <span className='mt-3 block'>{t('VatCode')}</span>
                                        <span className='block'>NL861513393B01</span>


                                    </span>
                                    <span className='mt-3 block'>
                                        <a className="hover:text-green-300" >{t("Call")}</a><br />
                                        <a className="hover:text-green-300">{t("ContactEmail")}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 sm:grid-cols-3 bg-white rounded-xl mt-[30px] sm:mt-[50px] p-[15px] md:p-[50px] text-black gap-y-[20px] sm:gap-y-0'>
                        <div className='sm:border-b-0 border-black/[.15] border-b pb-[20px] sm:pb-0 text-center sm:text-left'>
                            <div className='coulm-wrapper flex flex-col'>
                                <h5 className='mb-2'>{t("FollowUs")}</h5>
                                <Sociallist />
                            </div>
                        </div>
                        <div className='flex justify-center sm:border-b-0 border-black/[.15] border-b pb-[20px] sm:pb-0'>
                            <div className='coulm-wrapper flex flex-col'>
                                <div className='text-center sm:text-left'>
                                    <span className='font-semibold'>{t("MondayToFriday")}</span> : <span>7:00 - 18:00</span><br />
                                    <span className='font-semibold'>{t("SaturdaySunday")}</span> : <span>{t("Closed")}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center sm:justify-end'>
                            <div className='coulm-wrapper flex flex-col text-center sm:text-left'>
                                <h5 className=''>{t("CallUsToday")}!</h5>
                                <h3><a className='text-green-500 hover:text-green-700' >{t("Call")}</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className='flex w-[100%]  p-[15px] text-[#C1C1C1] text-center'>
                        <div className='w-[100%]'>
                            <div className='coulm-wrapper flex flex-col'>
                                <span className='text-sm mt-3'>© 2024 | dutso-dairy.com | <a
                                    className="inline-flex"
                                    href="https://delimp.com/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Designed &amp; Developed By Delimp{" "}
                                    <img src="https://pressboltme.ae/uploads/Vector-3.png" alt="logo--delimp" />
                                </a>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer