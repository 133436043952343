import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
// import { GoogleLogin } from "@react-oauth/google";
import { UserContext } from "../context/UserContext";
import { notifyError, notifySuccess } from "../utils/toast";
import CustomerServices from "../services/CustomerServices";
import { SidebarContext } from "../context/SidebarContext";
import { useCart } from "react-use-cart";

const useLoginSubmit = (setModalOpen) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { activeTab, setIsUpdate } = useContext(SidebarContext);
  const { emptyCart } = useCart();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const submitHandler = ({
    firstName,
    lastName,
    companyName,
    address,
    pincode,
    country,
    email,
    phone,
    registerEmail,
    verifyEmail,
    password,
  }) => {
    setLoading(true);
    const cookieTimeOut = 0.5;


    if (registerEmail && password) {
      CustomerServices.customerLogin({
        registerEmail,
        password,
      })
        .then((res) => {
          setLoading(false);
          if(res?.role === "Company"){
            emptyCart();
            navigate("/")
            window.location.reload()
          }
          setModalOpen(false);
          setIsUpdate(true)
          notifySuccess("Logged in Successfully!");
          dispatch({ type: "USER_LOGIN", payload: res });
          Cookies.set("userInfo", JSON.stringify(res), {
            expires: cookieTimeOut,
          });

        })
        .catch((err) => {
          const errorMsg = err.response?.data?.message || err.message || "Server Error"
          notifyError(errorMsg);
          setLoading(false);
        });
    }
    if (firstName && lastName && email && password) {
      let payload = { first_name: firstName, last_name: lastName, email, password }
      if (activeTab === 'Company') {
        const userData = { phone, company_name: companyName, country }
        Object.assign(payload, userData)
      }
      CustomerServices.registerCustomer(payload)
        .then((res) => {
          setLoading(false);
          setModalOpen(false);
          notifySuccess(res.message);
        })
        .catch((err) => {
          setLoading(false);
          const errorMsg = err.response?.data?.message || err.message || "Server Error"
          notifyError(errorMsg);
        });
    }

    if (verifyEmail) {
      CustomerServices.forgetPassword({ verifyEmail })
        .then((res) => {
          setLoading(false);
          notifySuccess(res.message);
          setValue("verifyEmail");
        })
        .catch((err) => {
          setLoading(false);
          const errorMsg = err.response?.data?.message || err.message || "Server Error"
          notifyError(errorMsg);
        });
    }
  };

  // const handleGoogleSignIn = (user) => {
  //   // console.log("google sign in", user?.credential);
  //   const cookieTimeOut = 0.5;

  //   if (user) {
  //     CustomerServices.signUpWithProvider(user?.credential)
  //       .then((res) => {
  //         setModalOpen(false);
  //         notifySuccess("Login success!");
  //         router.push(redirect || "/");
  //         dispatch({ type: "USER_LOGIN", payload: res });
  //         Cookies.set("userInfo", JSON.stringify(res), {
  //           expires: cookieTimeOut,
  //         });
  //       })

  //       .catch((err) => {
  //         notifyError(err.message);
  //         setModalOpen(false);
  //       });
  //   }
  // };

  return {
    handleSubmit,
    submitHandler,
    // handleGoogleSignIn,
    register,
    errors,
    // GoogleLogin,
    loading,
  };
};

export default useLoginSubmit;
