import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul
        className="fixed flex flex-col lg:flex-row lg:justify-center lg:gap-x-[20px] lg:items-center main--navigation right-0 z-[1] min-w-[300px] lg:min-w-[auto] lg:static translate-x-[100%] lg:translate-x-[0] transition duration-500 overflow-hidden bg-[#0078aba1] rounded-[10px] lg:bg-transparent">
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/">
            {t("NavHome")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/about">
            {t("NavAbout")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/shop">
            {t("NavShop")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/recipes">
            {t("NavRecipes")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/health">
            {t("NavHealth")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/blog">
            {t("NavBlog")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/contact-us">
            {t("NavContact")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/faq">
            {t("NavFaq")}
          </NavLink>
        </li>

        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/partner-with-us">
            {t("NavPartnerWithUs")}
          </NavLink>
        </li>

        {/* <li className={isActive ? "submenu-active nav-item relative" : "nav-item relative"}>
          <a
            onClick={handleClick}
            className="childelemrntIn flex gap-[5px] transition-all duration-300"
         >
            <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] border-b-[1px] border-[#ffffff3d] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="#">
              More <img className="inline-block" src={DownarrowIcon} />
            </NavLink>{" "}
          </a>
          <div className="dropdownmenu absolute min-w-[250px] rounded-[10px] transform translate-y-[0px] opacity-0 transition-all duration-300 overflow-hidden">
            <ul className="subMenu bg-[color:var(--color-primary)]">
              <li className="nav-item">
                <NavLink className="text-white transition hover:bg-[color:var(--color-green)]" to="/product-single">
                  Product Single
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

      </ul>
    </>
  );
};

export default Navbar;
