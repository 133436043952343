import dayjs from "dayjs";
import useGetSetting from "./useGetSetting";
import { dateFormat, dateTimeFormat, timeFormat } from "../utils/constants";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";


const useUtilsFunction = () => {
    const {lang} =  useContext(SidebarContext)
    const limitDesc = 80;

    const { globalSetting } = useGetSetting();

    const currency = "€";

    //for date and time format
    const showTimeFormat = (data, timeformat = timeFormat) => {
        return dayjs(data).format(timeformat);
    };

    const showDateFormat = (data, dateformat = dateFormat) => {
        return dayjs(data).format(dateformat);
    };

    const showDateTimeFormat = (data, datetimeformat = dateTimeFormat) => {
        return dayjs(data).format(datetimeformat);
    };

    //for formatting number

    const getNumber = (value = 0) => {
        return Number(parseFloat(value || 0).toFixed(2));
    };

    const getNumberTwo = (value = 0) => {
        return parseFloat(value || 0).toFixed(globalSetting?.floating_number || 2);
    };

    //for translation
    const showingTranslateValue = (data) => {
        return data !== undefined && Object?.keys(data).includes(lang)
            ? data[lang]
            : data?.en;
    };

    //for translation
    const showingIngredients = (data) => {
        return data !== undefined && Object?.keys(data).includes(lang)
            ? data[lang]
            : data?.en;
    };

    const showingImage = (data) => {
        return data !== undefined && data;
    };

    const showingUrl = (data) => {
        return data !== undefined ? data : "!#";
    };
    // dutso-ghee-250-grams-tin
    const getTinAndBoxPrice = (cart) => {
        if (cart.slug === 'dutso-butter-ghee-250-grams-tin' || cart.slug === 'dutso-ghee-250-grams-tin') {
            let palletPrice = parseFloat(cart.price);
            let boxesPerPallet = 48;
            let tinsPerBox = 36;
            let totalTins = boxesPerPallet * tinsPerBox;
            let tinPrice = (palletPrice / totalTins)?.toFixed(2);
            let boxPrice = (palletPrice / boxesPerPallet)?.toFixed(2);
            return { tinPrice, boxPrice , boxesPerPallet , tinsPerBox}

        }
        else if (cart.slug === 'dutso-butter-ghee-500-grams-tin') {
            let palletPrice = parseFloat(cart.price);
            let boxesPerPallet = 48;
            let tinsPerBox = 24;
            let totalTins = boxesPerPallet * tinsPerBox;
            let tinPrice = (palletPrice / totalTins)?.toFixed(2);
            let boxPrice = (palletPrice / boxesPerPallet)?.toFixed(2);
            return { tinPrice, boxPrice , boxesPerPallet , tinsPerBox }
        }

        else if (cart.slug === 'dutso-butter-ghee-1-kilograms-tin') {
            let palletPrice = parseFloat(cart.price);
            let boxesPerPallet = 48;
            let tinsPerBox = 12;
            let totalTins = boxesPerPallet * tinsPerBox;
            let tinPrice = (palletPrice / totalTins)?.toFixed(2);
            let boxPrice = (palletPrice / boxesPerPallet)?.toFixed(2);
            return { tinPrice, boxPrice , boxesPerPallet , tinsPerBox }
        }

        else if (cart.slug === 'dutso-butter-ghee-2-kilograms-tin') {
            let palletPrice = parseFloat(cart.price);
            let boxesPerPallet = 45;
            let tinsPerBox = 6;
            let totalTins = boxesPerPallet * tinsPerBox;
            let tinPrice = (palletPrice / totalTins)?.toFixed(2);
            let boxPrice = (palletPrice / boxesPerPallet)?.toFixed(2);
            return { tinPrice, boxPrice , boxesPerPallet , tinsPerBox }
        }

        else if (cart.slug === 'dutso-butter-ghee-15-kilograms-tin') {
            let palletPrice = parseFloat(cart.price);
            let boxesPerPallet = 45;
            let tinsPerBox = 1;
            let totalTins = boxesPerPallet * tinsPerBox;
            let tinPrice = (palletPrice / totalTins)?.toFixed(2);
            let boxPrice = (palletPrice / boxesPerPallet)?.toFixed(2);
            return { tinPrice, boxPrice , boxesPerPallet , tinsPerBox }
        }
        else {
            return { tinPrice: "NA", boxPrice: "NA" , boxesPerPallet : "45" , tinsPerBox : "12" }
        }
    }

    return {
        lang,
        currency,
        getNumber,
        getNumberTwo,
        showTimeFormat,
        showDateFormat,
        showingImage,
        showingUrl,
        showDateTimeFormat,
        showingTranslateValue,
        showingIngredients,
        limitDesc,
        getTinAndBoxPrice
    };
};

export default useUtilsFunction;
