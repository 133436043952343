import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import RoootRouter from './Routers/RootRouters';
import { SidebarProvider } from "./context/SidebarContext";
import { UserProvider } from './context/UserContext';
import { ToastContainer } from "react-toastify";
import { CartProvider } from "react-use-cart";


function App() {
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <>
      <UserProvider>
        <SidebarProvider>
          <CartProvider>
            <RoootRouter />
          </CartProvider>
        </SidebarProvider>
      </UserProvider>
      <ToastContainer />
    </>
  );
}

export default App;
