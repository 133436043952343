import React, { useState, useMemo, createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { setProduct } from '../redux/reducers/appSlice';
import { useNavigate } from "react-router-dom";


export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [categoryDrawerOpen, setCategoryDrawerOpen] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Individual');
  const { i18n } = useTranslation();
  const [lang, setLang] = useState("en");
  const [isUpdate, setIsUpdate] = useState(false);
  const [category, setCategory] = useState("");


  const toggleCartDrawer = () => setCartDrawerOpen(!cartDrawerOpen);
  const closeCartDrawer = () => {
    setCartDrawerOpen(false)
  };

  const toggleCategoryDrawer = () => setCategoryDrawerOpen(!categoryDrawerOpen);
  const closeCategoryDrawer = () => setCategoryDrawerOpen(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(false);

  const handleChangePage = (p) => {
    setCurrentPage(p);
  };

  const navigateToProductDetails = (product) => {
    dispatch(setProduct(product))
    navigate(`/product/${product?.slug}`)
  }

  const handleLanguageChange = (lang) => {
    Cookies.set("i18next", lang, {
      sameSite: "None",
      secure: true,
    });
    i18n.changeLanguage(lang);
    setLang(lang);
  };

  useEffect(() => {
    const dir = i18n.dir(i18n.language);
    document.documentElement.dir = dir;
  }, [i18n, i18n.language]);


  useEffect(() => {
    const lang = Cookies.get("i18next");
    const removeRegion = (langCode) => {
      const updatedLang = langCode?.split("-")[0];
      return updatedLang;
    };

    const updatedLang = removeRegion(lang);
    setLang(updatedLang);
    Cookies.set("i18next", updatedLang, {
      sameSite: "None",
      secure: true,
    });
  }, [lang]);

  const value = useMemo(
    () => ({
      cartDrawerOpen,
      toggleCartDrawer,
      closeCartDrawer,
      setCartDrawerOpen,
      categoryDrawerOpen,
      toggleCategoryDrawer,
      closeCategoryDrawer,
      isModalOpen,
      toggleModal,
      closeModal,
      currentPage,
      setCurrentPage,
      handleChangePage,
      isLoading,
      setIsLoading,
      activeTab,
      setActiveTab,
      modalOpen,
      setModalOpen,
      lang,
      setLang,
      handleLanguageChange,
      navigateToProductDetails,
      showRegister,
      setShowRegister,
      isUpdate,
      setIsUpdate,
      category,
      setCategory,
    }),

    [cartDrawerOpen, categoryDrawerOpen, isModalOpen, currentPage, isLoading, activeTab, modalOpen, lang, showRegister, category ]
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};
