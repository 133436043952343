import React, { useContext } from 'react';
import { FiLock, FiMail, FiUser, FiFlag, FiPhone, } from "react-icons/fi";
import { RiBuilding2Line, RiLockPasswordLine } from "react-icons/ri";
import Error from "../form/Error";
import InputArea from "../form/InputArea";
import useLoginSubmit from "../../hooks/useLoginSubmit";
import { SidebarContext } from "../../context/SidebarContext";
import { useTranslation } from 'react-i18next';

const Register = ({ setShowResetPassword, setModalOpen }) => {
  const { activeTab, setActiveTab } = useContext(SidebarContext);
  const { t } = useTranslation();

  const { handleSubmit, submitHandler, register, errors, loading } =
    useLoginSubmit(setModalOpen);

  return (
    <div className="w-full flex flex-col">
      <div className="w-full">
        <div className={` ${activeTab === 'Individual' ? 'bg-[color:var(--color-green-light)]' : 'bg-[color:var(--color-input)]'} text-center md:[0]  p-[20px] pb-[80px] mb-[-50px] mt-[20px] md:mt-0`}>
          <img alt ="dutso" className='block w-[90px]  mx-auto' src={require('../../assets/images/dustso-logo.png')} />
        </div>
        <div className="popup_tabs pt-0 lg:pt-0">
          <div className="tabs">
            <button type='button' onClick={() => setActiveTab('Individual')} className={` ${activeTab === 'Individual' ? 'active' : ''} `}>{t('Individual')}</button>
            <button type='button' onClick={() => setActiveTab('Company')} className={` ${activeTab === 'Company' ? 'active' : ''} `}>{t('Company')}</button>
          </div>
          <div className="tab-content">
            {activeTab === 'Individual' &&
              <div className=" p-[15px] lg:p-[50px]">
                <p className="text-[color:var(--color-primary)] mb-[15px]">{t("RegisterTitle")}</p>
                <form
                  onSubmit={handleSubmit(submitHandler)}
                  className="flex flex-col justify-center"
                >
                  <div className="grid grid-cols-1 gap-3">
                    <div className="grid grid-col-1 sm:grid-cols-2 gap-3">
                      <div className="form-group">
                        <InputArea
                          register={register}
                          label={t("FirstName")}
                          name="firstName"
                          type="text"
                          placeholder={t("FirstName")}
                          Icon={FiUser}
                        />
                        <Error errorName={errors.firstName} />
                      </div>
                      <div className="form-group col-span-1">
                        <InputArea
                          register={register}
                          label={t("LastName")}
                          name="lastName"
                          type="text"
                          placeholder={t("LastName")}
                          Icon={FiUser}
                        />
                        <Error errorName={errors.lastName} />
                      </div>
                    </div>
                    <div className="form-group">
                      <InputArea
                        register={register}
                        label={t("Email")}
                        name="email"
                        type="email"
                        placeholder={t("Email")}
                        Icon={FiMail}
                      />
                      <Error errorName={errors.email} />
                    </div>
                    <div className="form-group">
                      <InputArea
                        register={register}
                        label={t("Password")}
                        name="password"
                        type="password"
                        placeholder={t("Password")}
                        Icon={RiLockPasswordLine}
                      />
                      <Error errorName={errors.password} />
                    </div>
                    {loading ? (
                      <button
                        disabled={loading}
                        type="submit"
                        className="md:text-sm leading-5 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none bg-emerald-500 text-white px-5 md:px-6 lg:px-8 py-2 md:py-3 lg:py-3 hover:text-white hover:bg-emerald-600 h-12 mt-1 text-sm lg:text-sm w-full sm:w-auto"
                      >
                        <img
                          src="/loader/spinner.gif"
                          alt="Loading"
                          width={20}
                          height={10}
                        />
                        <span className="font-serif ml-2 font-light">{t('Processing')}</span>
                      </button>
                    ) : (
                      <button
                        disabled={loading}
                        type="submit"
                        className="w-full text-center py-3 rounded bg-[color:var(--color-primary)] text-white hover:bg-[color:var(--color-green)] transition-all focus:outline-none my-1"
                      >
                        {t('Register')}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            }

            {activeTab === 'Company' &&
              <div className="pb-[25px] pt-[10px]">
                <p className="text-[color:var(--color-primary)] mb-[15px]">{t('SignUpTitle')}</p>
                <form
                  onSubmit={handleSubmit(submitHandler)}
                  className="flex flex-col justify-center"
                >
                  <div className="grid grid-cols-1 gap-3">
                    <div className="grid grid-col-1 sm:grid-cols-2 gap-2">
                      <div className="form-group">
                        <InputArea
                          register={register}
                          label={t("FirstName")}
                          name="firstName"
                          type="text"
                          placeholder={t("FirstName")}
                          Icon={FiUser}
                        />
                        <Error errorName={errors.firstName} />
                      </div>
                      <div className="form-group">
                        <InputArea
                          register={register}
                          label={t("LastName")}
                          name="lastName"
                          type="text"
                          placeholder={t("LastName")}
                          Icon={FiUser}
                        />
                        <Error errorName={errors.lastName} />
                      </div>
                    </div>
                    <div className="grid grid-col-1 sm:grid-cols-2 gap-2">
                      <div className="form-group">
                        <InputArea
                          register={register}
                          label={t("CompanyName")}
                          name="companyName"
                          type="text"
                          placeholder={t("CompanyName")}
                          Icon={RiBuilding2Line}
                        />
                        <Error errorName={errors.companyName} />
                      </div>
                      <div className="form-group">
                        <InputArea
                          register={register}
                          label={t("PhoneNumber")}
                          name="phone"
                          type="number"
                          placeholder={t("PhoneNumber")}
                          Icon={FiPhone}
                        />
                        <Error errorName={errors.phone} />
                      </div>
                    </div>
                    <div className="form-group">
                      <InputArea
                        register={register}
                        label={t("Email")}
                        name="email"
                        type="email"
                        placeholder={t("Email")}
                        Icon={FiMail}
                      />
                      <Error errorName={errors.email} />
                    </div>

                    {/* <div className="form-group">
                      <InputArea
                        register={register}
                        label="Address"
                        name="address"
                        type="text"
                        placeholder="Address"
                        Icon={FiMapPin}
                      />
                      <Error errorName={errors.address} />
                    </div> */}

                    <div className="grid grid-col-1 sm:grid-cols-2 gap-2">
                      <div className="form-group col-span-1">
                        <InputArea
                          register={register}
                          label={t("Country")}
                          name="country"
                          type="text"
                          placeholder={t("Country")}
                          Icon={FiFlag}
                        />
                        <Error errorName={errors.country} />
                      </div>
                      <div className="form-group">
                        <InputArea
                          register={register}
                          label={t("Password")}
                          name="password"
                          type="password"
                          placeholder={t("Password")}
                          Icon={RiLockPasswordLine}
                        />
                        <Error errorName={errors.password} />
                      </div>
                    </div>

                    {/* <div className="grid grid-col-1 sm:grid-cols-2 gap-2">
                    <div className="form-group">
                      <InputArea
                        register={register}
                        label="Password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        Icon={FiLock}
                      />
                      <Error errorName={errors.password} />
                    </div>
                    <div className="form-group">
                      <InputArea
                        register={register}
                        label="Confirm Password"
                        name="confirmpassword"
                        type="password"
                        placeholder="Confirm Password"
                        Icon={FiLock}
                      />
                      <Error errorName={errors.password} />
                    </div>
                    </div> */}

                    {loading ? (
                      <button
                        disabled={loading}
                        type="submit"
                        className="md:text-sm leading-5 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none bg-emerald-500 text-white px-5 md:px-6 lg:px-8 py-2 md:py-3 lg:py-3 hover:text-white hover:bg-emerald-600 h-12 mt-1 text-sm lg:text-sm w-full sm:w-auto"
                      >
                        <img
                          src="/loader/spinner.gif"
                          alt="Loading"
                          width={20}
                          height={10}
                        />
                        <span className="font-serif ml-2 font-light">{t('Processing')}</span>
                      </button>
                    ) : (
                      <button
                        disabled={loading}
                        type="submit"
                        className="w-full text-center py-3 rounded bg-[color:var(--color-primary)] text-white hover:bg-[color:var(--color-green)] transition-all focus:outline-none my-1"
                      >
                        {t('Signup')}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
