import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import { useCart } from "react-use-cart";
import { IoBagCheckOutline, IoClose, IoBagHandle } from "react-icons/io5";

//internal import
import CartItem from "./CartItem";
import LoginModal from "../modal/LoginModal";
import { UserContext } from "../../context/UserContext";
import { SidebarContext } from "../../context/SidebarContext";
import useUtilsFunction from "../../hooks/useUtilsFunction";
import { useTranslation } from "react-i18next";

const Cart = () => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const { isEmpty, items, cartTotal, totalItems } = useCart();
  const { toggleCartDrawer, closeCartDrawer, cartDrawerOpen } = useContext(SidebarContext);
  const { currency } = useUtilsFunction();
  const { state: { userInfo } } = useContext(UserContext);

  const handleOpenLogin = () => {
    toggleCartDrawer();
    setModalOpen(!modalOpen);
  };

  const checkoutClass = (
    <button
      onClick={closeCartDrawer}
      className="w-full py-3 px-3 rounded-lg bg-[color:var(--color-primary)] hover:bg-[color:var(--color-green)] flex items-center justify-between bg-heading text-sm sm:text-base text-white focus:outline-none transition duration-300"
    >
      <span className="align-middle font-medium font-serif">
        {userInfo?.role === "Company" ? t('SendRequest') : t('ProceedToCheckout')}
      </span>
      {cartTotal > 0 &&
        <span className="rounded-lg font-bold font-serif py-2 px-3 bg-white text-emerald-600">
          {currency}
          {cartTotal.toFixed(2)}
        </span>
      }
    </button>
  );


  return (
    <>
      {modalOpen && (
        <LoginModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}
      <div className={`menuRightpopup flex flex-col w-full h-full justify-between items-middle bg-white rounded cursor-pointer ${cartDrawerOpen ? "cart-visible" : ""} `}>
        <div className="w-full flex justify-between items-center relative px-5 py-4 border-b bg-indigo-50 border-gray-100">
          <h3 className="m-0 flex items-center text-[color:var(--color-primary)]">
            <span className="mr-2 mb-1">
              <IoBagCheckOutline />
            </span>
            {t("ShoppingCartTitle")}
          </h3>
          <button
            onClick={closeCartDrawer}
            className="inline-flex text-base items-center justify-center text-gray-500 p-2 focus:outline-none transition-opacity hover:text-[color:var(--color-red)]"
          >
            <IoClose />
            <span className="font-sens text-sm text-gray-500 hover:text-[color:var(--color-red)] ml-1">
              {t("CloseBtn")}
            </span>
          </button>
        </div>
        <div className="overflow-y-auto flex-grow scrollbar-hide w-full max-h-full">
          {isEmpty && (
            <div className="flex flex-col h-full justify-center">
              <div className="flex flex-col items-center">
                <div className="flex justify-center items-center w-20 h-20 rounded-full bg-[#d5f2ff]">
                  <span className="text-[color:var(--color-primary)] text-4xl block">
                    <IoBagHandle />
                  </span>
                </div>
                <h3 className="pt-5">
                  {t("YourCartEmpty")}
                </h3>
                <p className="px-12 text-center text-sm text-gray-500 pt-2">
                  {t("NoItemAddedTitle")}
                </p>
              </div>
            </div>
          )}
          {items.map((item, i) => (
            <CartItem key={i + 1} item={item} />
          ))}
        </div>
        <div className="mx-5 my-3">
          {items.length <= 0 ? (
            checkoutClass
          ) : (
            <span>
              {!userInfo ? (
                <div onClick={handleOpenLogin}>{checkoutClass}</div>
              ) : (
                <Link to="/checkout">{checkoutClass}</Link>
              )}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
