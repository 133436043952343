import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    product: '',
    attributes: '',
    blog: '',
    recipe: '',
    loadType: "",
    banners: []
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setProduct: (state, action) => {
            state.product = action.payload;
        },
        setBlog: (state, action) => {
            state.blog = action.payload;
        },
        setRecipe: (state, action) => {
            state.recipe = action.payload;
        },
        setBanners: (state, action) => {
            state.banners = action.payload;
        },

        setLoadType: (state, action) => {
            state.loadType = action.payload;
        },

    },
});

export const { setProduct, setBlog, setRecipe, setBanners , setLoadType } = appSlice.actions;

export default appSlice.reducer;
