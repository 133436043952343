import React, { useContext } from 'react';
// import Drawer from 'rc-drawer';

// Internal import
import Cart from '../cart/Cart';
import { SidebarContext } from '../../context/SidebarContext';

const CartDrawer = () => {
  const { cartDrawerOpen, closeCartDrawer } = useContext(SidebarContext);

  return (
    // <Drawer
    //   open={cartDrawerOpen}
    //   onClose={closeCartDrawer}
    //   parent={null}
    //   level={null}
    //   placement={'right'}
    // >
      <Cart />
    // </Drawer>
  );
};

export default CartDrawer;
