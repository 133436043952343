import React from "react";
import Label from "./Label";

const InputArea = ({
  name,
  label,
  type,
  Icon,
  register,
  defaultValue,
  autocomplete,
  placeholder,
}) => {
  return (
    <>
      {/* <Label label={label} /> */}
      <div className="relative">
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-[color:var(--color-primary)] focus-within:text-gray-900 sm:text-base">
              <Icon />{" "}
            </span>
          </div>
        )}
        <input
          {...register(`${name}`, {
            required: `${label} is required!`,
          })}
          type={type}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoComplete={autocomplete}
          className={
            Icon
              ? "py-2 pl-10 w-full appearance-none border text-sm text-input rounded-md placeholder-body placeholder:text-[color:var(--color-primary)] min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-[color:var(--color-input)] border-[color:var(--color-input)] focus:outline-none focus:border-[color:var(--color-primary)] h-11 md:h-12 text-[color:var(--color-primary)]"
              : "py-2 px-4 md:px-5 w-full appearance-none border text-sm text-input rounded-md placeholder-body placeholder:text-[color:var(--color-primary)] min-h-12 transition duration-200 focus:ring-0 ease-in-out  border-[color:var(--color-input)] focus:outline-none focus:border-emerald-500 h-11 md:h-12"
          }
        />
      </div>
    </>
  );
};

export default InputArea;
