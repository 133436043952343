import axios from "axios";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { UserContext } from "../context/UserContext";
import { useLocation } from "react-router-dom";


const useAsync = (asyncFunction) => {
  const location = useLocation();
  const pathName =  location.pathname
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [errCode, setErrCode] = useState("");
  const [loading, setLoading] = useState(true);
  const { state: { userInfo } } = useContext(UserContext);
  const {
    isUpdate,
    setIsUpdate,
    category,
  } = useContext(SidebarContext);

  useEffect(() => {
    let unmounted = false;
    let source = axios.CancelToken.source();
    (async () => {
      try {
        const res = await asyncFunction({ cancelToken: source.token });
        if (!unmounted) {
          if ((res && res[0]?.prices) && (userInfo && userInfo.role === 'Company')) {
            const updatedProducts = res.map(product => {
              if (product.prices) {
                return {
                  ...product,
                  prices: {
                    ...product.prices,
                    price: 0,
                    originalPrice: 0,
                    discount: product.prices.discount || 0,
                  }
                };
              }
              return product;
            });
            setData(updatedProducts)
          }
          else {
            setData(res);
          }
          setError("");
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);

        setErrCode(err?.response?.status);
        if (!unmounted) {
          setError(err.message);
          if (axios.isCancel(err)) {
            setError(err.message);
            setData([]);
          } else {
            setError(err.message);
            setData([]);
          }
        }
      }
    })();
    setIsUpdate(false);

    return () => {
      unmounted = true;
      source.cancel("Cancelled in cleanup");
    };
  }, [isUpdate, category , pathName]);

  return {
    data,
    error,
    loading,
  };
};

export default useAsync;
