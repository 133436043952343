import React from "react";
import Common from "../login/Common";
import MainModal from "../modal/MainModal";

const LoginModal = ({ modalOpen, setModalOpen }) => {
  return (
    <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="inline-flex w-full max-w-[1000px] p-[10px] overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
        <div className="flex flex-wrap w-full">
        <div className="w-[100%] md:w-7/12 pr-[0px] md:pr-[15px]">
          <img className="rounded-full md:rounded-none w-[300px] md:w-full m-auto aspect-square md:aspect-auto md:h-[600px] object-cover object-right-bottom" src={require("../../assets/images/popup-left.png")} />
        </div>
        <div className="w-[100%] md:w-5/12 relative flex items-center">
        <Common setModalOpen={setModalOpen} />
        </div>
        </div>
        
      </div>
    </MainModal>
  );
};

export default LoginModal;
