import React, { useEffect, useState, useContext } from 'react';
import PhoneIcon from '../assets/images/phone.svg';
import userIcon from '../assets/images/user.svg';
import AlertIcon from '../assets/images/alert.svg';
import DownloadIcon from '../assets/images/download.svg';
import TotalIcon from '../assets/images/ButtonTotal.svg';
import dumyProfile from '../assets/images/circle-wave.png';
import Navbar from './Navbar';
import LoginModal from "../Components/modal/LoginModal";
import { SidebarContext } from '../context/SidebarContext';
import CartDrawer from "../Components/drawer/CartDrawer";
import { useCart } from 'react-use-cart';
import { UserContext } from "../context/UserContext";
import Cookies from "js-cookie";
import { Link, useNavigate } from 'react-router-dom';
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from 'react-i18next';
import CmsServices from '../services/CmsServices';
import useAsync from '../hooks/useAsync';
import { useDispatch } from 'react-redux';
import { setBanners } from '../redux/reducers/appSlice';


const Header = () => {
    const [addclass, setAddclass] = useState(false);
    const { t } = useTranslation();
    const dispatch1 = useDispatch();
    const navigate = useNavigate();
    const { totalItems, emptyCart } = useCart();
    const [imageUrl, setImageUrl] = useState("");

    const [selectedLanguage, setSelectedLanguage] = useState("US");
    const { dispatch, state: { userInfo } } = useContext(UserContext);
    const { modalOpen, setModalOpen, toggleCartDrawer, handleLanguageChange } = useContext(SidebarContext);
    let { data } = useAsync(() => CmsServices.getAllBanners());

    if (data?.banners) {
        dispatch1(setBanners(data.banners));
    }


    useEffect(() => {
        if (Cookies.get("userInfo")) {
            const user = JSON.parse(Cookies.get("userInfo"));
            if (user?.image) {
                setImageUrl(user.image);
            }
        }
    }, []);

    const handleLogOut = () => {
        dispatch({ type: "USER_LOGOUT" });
        Cookies.remove("userInfo");
        Cookies.remove("couponInfo");
        setImageUrl("")
        emptyCart();
        navigate("/");
        window.location.reload()
    };

    const handleChangeLanguage = (lang) => {
        const langCode = lang.toLowerCase();
        setSelectedLanguage(lang);
        const language = langCode === "us" ? "en" : langCode;
        handleLanguageChange(language);
    }

    return (
        <>
            <CartDrawer />
            {modalOpen && (
                <LoginModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
            )}
            <header className='Header z-[10] fixed top-0 left-0 w-full backdrop-blur-[15px]'>
                <div className='Topbar bg-[color:var(--color-primary)]'>
                    <div className='TopbarWrapper flex container m-auto items-center text-white text-sm/[12px] font-light p-[10px] lg:p-[15px] justify-between'>
                        <div className=''>
                            <span className='hidden md:inline-block mr-[10px]'>
                                <img alt='phone_icon' className='inline-block mr-[8px]' src={PhoneIcon} />We are available 24/7, Need help? </span>
                            <a href='tel:31412849120' className='font-bold inline-block text-[12px] md:text-[14px] hover:text-[color:var(--color-sky)] transition'>+31 412 84 91 20</a>
                        </div>
                        <div className='flex items-center justify-between'>
                            <div>
                                <ul className='signupLogin flex justify-end gap-[10px] items-center'>
                                    <li className='border-[#ffffff99] w-8'>
                                        <img alt='profile' className='rounded-3xl' src={imageUrl ? imageUrl : dumyProfile} />
                                    </li>
                                    {userInfo?.email &&
                                        <li className='border-r-2 border-[#ffffff99]'>
                                            <Link to="/user/my-orders" className='flex gap-[5px] pr-[10px] cursor-pointer text-[12px] md:text-[14px] hover:text-[color:var(--color-sky)] transition'>
                                                {t("MyAccount")}
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        {userInfo?.email ?
                                            <span className="flex gap-[5px] pl-[10px] cursor-pointer text-[12px] md:text-[14px] hover:text-[color:var(--color-sky)] transition" onClick={handleLogOut}>
                                                <img alt='User' src={userIcon} />
                                                {t("Logout")}
                                            </span>
                                            :
                                            <span onClick={() => setModalOpen(!modalOpen)} className='flex gap-[5px] pl-[10px] cursor-pointer text-[12px] md:text-[14px] hover:text-[color:var(--color-sky)] transition'>
                                                <img alt='user' src={userIcon} />
                                                {t("Login")}
                                            </span>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className='flex items-center'>
                                <a
                                    href={`${process.env.REACT_APP_BASEURL}/Dutso_Dairy_Catalogue_2024.pdf`}
                                    target='_blank'
                                    download
                                    rel="noreferrer"
                                    className='flex gap-[5px] items-center pl-[10px] text-[12px] md:text-[14px] hover:text-[color:var(--color-sky)] transition'
                                >
                                    <img className='w-6 h-5' alt='download' src={DownloadIcon} />
                                    {t("Download")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mainHeader">
                    <div className="NavBar-wrapper justify-end flex container m-auto p-[15px] items-center">
                        <div className='w-[50px] m-auto ml-0 md:w-[90px] Logo'>
                            <Link to='/'><img className='block' alt='dutso-dairy' src={require('../assets/images/dustso-logo.png')} /></Link>
                        </div>
                        <div className='w-[40px] md:w-[calc(100%-270px)] navBar order-3 lg:order-2'>
                            <div className={addclass ? 'toggle-actve' : 'null'}>
                                <div className='mobileMenu block lg:hidden '>
                                    <span className="togle-contain bg-[#0078ab] h-[40px] w-[40px] flex flex-col justify-center items-center gap-[7px] rounded-[6px] p-[6px] ml-auto " onClick={() => setAddclass(!addclass)}>
                                        <span className="togle-line block w-[100%] bg-[#fff] h-[1px] transition-all	duration-300"></span>
                                        <span className="togle-line block w-[100%] bg-[#fff] h-[1px] transition-all	duration-300"></span>
                                        <span className="togle-line block w-[100%] bg-[#fff] h-[1px] transition-all	duration-300"></span>
                                    </span>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                        <div className='mr-[10px] lg:mr-[0px] flex justify-between order-2 lg:order-3 content-center gap-5'>
                            <div className="languageSelect min-w-7">
                                <ReactFlagsSelect
                                    countries={["US", "DE", "NL", "FR", "AE"]}
                                    customLabels={{ US: "English", DE: "German", NL: "Dutch", FR: "French", AE: "Arabic" }}
                                    selected={selectedLanguage}
                                    onSelect={(countryCode) => handleChangeLanguage(countryCode)}
                                />
                            </div>
                            <div className="cartAlert mt-px">
                                <ul className='flex justify-end gap-[15px]'>
                                    {/* <li><div className='Notification relative flex'><img src={AlertIcon} /></div></li> */}
                                    <li>
                                        <div onClick={toggleCartDrawer} className='cartItem relative flex cursor-pointer transition group '>
                                            <img alt='total' src={TotalIcon} />
                                            <span className='CartValue absolute top-[-14px] right-[10px]	h-[20px] w-[20px] rounded-full bg-[color:var(--color-red)] group-hover:bg-[color:var(--color-primary)] transition-all  text-white text-center leading-[20px] text-[10px]'>
                                                {totalItems}
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;
