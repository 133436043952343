import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";
// import DownarrowIcon from "../assets/images/down-arrow.svg";


const Navbarfooter = () => {
  const { t } = useTranslation();



  return (
    <>
      <ul className="flex flex-col lg:flex-row lg:justify-center lg:gap-x-[20px] lg:items-center main--navigation right-0 z-[1] min-w-[300px] lg:min-w-[auto] lg:static lg:translate-x-[0] transition duration-500 overflow-hidden rounded-[10px] lg:bg-transparent">
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/terms-condition">
            {t("TermsConditions")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/disclaimer">
            {t("Disclaimer")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/privacy-policy">
            {t("PrivacyPolicy")}
          </NavLink>
        </li>
        <li className="nav-item relative">
          <NavLink className="hover:text-[color:var(--color-primary)] block text-center p-[10px] lg:p-[5px] text-white lg:text-black lg:border-0 backdrop-blur-[20px]" to="/return-policy">
            {t("RefundPolicy")}
          </NavLink>
        </li>

      

      </ul>
    </>
  );
};

export default Navbarfooter;
