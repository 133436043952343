import { Link } from "react-router-dom";
import React from "react";
import { FiMail } from "react-icons/fi";

//internal import
import Error from "../form/Error";
import InputArea from "../form/InputArea";
import useLoginSubmit from "../../hooks/useLoginSubmit";
import { useTranslation } from "react-i18next";

const ResetPassword = ({ setShowResetPassword, setModalOpen }) => {
  const { t } = useTranslation();
  const { handleSubmit, submitHandler, register, errors, loading } = useLoginSubmit(setModalOpen);

  return (
    <div className="w-full p-[15px] lg:p-[50px] flex items-center">
      <div className="w-full">
        <div className="text-center mb-[15px]">
          <img alt="dutso" className='block w-[90px] mt-[30px] md:mt-[0] mx-auto mb-[30px] lg:mb-[50px]' src={require('../../assets/images/dustso-logo.png')} />
          <h3 className="uppercase">{t('ForgetPassword')}</h3>
        </div>
        <form
          onSubmit={handleSubmit(submitHandler)}
          className="flex flex-col justify-center"
        >
          <div className="grid grid-cols-1 gap-3">
            <div className="form-group">
              <InputArea
                register={register}
                label={t("Email")}
                name="verifyEmail"
                type="email"
                placeholder={t("YourRegisterEmail")}
                Icon={FiMail}
              />
              <Error errorName={errors.verifyEmail} />
            </div>
            <div className="flex ms-auto">
              <button
                type="button"
                onClick={() => setShowResetPassword(false)}
                className="text-end text-sm text-[color:var(--color-primary)] ps-3 underline hover:no-underline focus:outline-none"
              >
                {t("Login")}
              </button>
            </div>
            {loading ? (
              <button
                disabled={loading}
                type="submit"
                className="md:text-sm leading-5 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md placeholder-white focus-visible:outline-none focus:outline-none bg-emerald-500 text-white px-5 md:px-6 lg:px-8 py-2 md:py-3 lg:py-3 hover:text-white hover:bg-emerald-600 h-12 mt-1 text-sm lg:text-sm w-full sm:w-auto"
              >
                <img
                  src="/loader/spinner.gif"
                  alt="Loading"
                  width={20}
                  height={10}
                />
                <span className="font-serif ml-2 font-light">{t('Processing')}</span>
              </button>
            ) : (
              <button
                disabled={loading}
                type="submit"
                className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
              >
                {t('RecoverPassword')}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
